import Modules from "./modules";

/**
 * Class NotFound
 */
class NotFound {
    /**
     * Class constructor
     * @param {HTMLElement} module
     */
    constructor(module) {}

    /**
     * Returns the class name
     * @return {string}
     */
    static getName() {
      return "js-not_found";
    }
}

new Modules(NotFound);